<template>
  <div class="login-container">
    <div class="login-inner">
      <div class="login-header">
        <div class="tit color4">
          <div class="fs26 fb mb5">仓小福零售收银系统</div>
          <div class="">新零售·兴百业</div>
        </div>
      </div>
      <div class="login-content">
        <van-form @submit="login">
          <van-field
            v-model="loginForm.username"
            name="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="loginForm.password"
            type="password"
            name="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <van-field
            type="text"
            maxlength="4"
            placeholder="验证码"
            v-model="loginForm.captcha"
          >
            <template #button>
              <img
                class="get-verification"
                :src="codeUrl"
                alt="captcha"
                @click="getCaptcha"
                ref="captcha"
              />
            </template>
          </van-field>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit"
              >登录</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
    <div class="rightcopy">
      <div class="divText tac">
        有任何问题请联系：4001033005 工作时间：周一至周六，上午8:30-下午6:00
      </div>
      <div class="divText tac">备案号：湘ICP备19008991号-3</div>
    </div>
  </div>
</template>

<script>
import {login,getCaptchaCode } from '../../service/api/product'
import { Toast } from "vant";
import { mapActions } from "vuex";

export default {
  name: "Login",

  data() {
    return {
      loginMode: false, // 1. 登录模式
      passwordMode: true, // 2. 密码模式
      phone: null, // 3. 手机号码
      countDown: 0, // 4. 倒计时
      code: null, // 5. 手机验证码
      userInfo: null, // 6. 接收用户信息
      userName: null, // 7. 用户名
      userPwd: null, // 8. 密码
      captcha: null, // 9. 图形验证码
      codeUrl: null,
      sn: null,
      loginForm: {
        username: "",
        password: "",
        captcha: "",
        sn: "",
      },
    };
  },
  methods: {
    ...mapActions(["syncUserInfo"]),
    back() {
      // 1. 放回上一级路由
      this.$router.back();
    },
    dealLoginMode(flag) {
      // 2. 处理登录模式
      this.loginMode = flag;
    },
    dealPasswordMode(flag) {
      // 3. 处理密码登录模式
      this.passwordMode = flag;
    },
    async login() {
      login(this.loginForm).then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$router.push("/dashboard");
        } else {
          Toast({
            message: res.msg,
            duration: 500,
          });
        }
      });
    },
    getCaptcha() {
      // 1. 获取验证码标签
      //   let captcha = this.$refs.captcha;

      getCaptchaCode().then((res) => {
        if (res.code == 200) {
          this.codeUrl = res.data.image;
          this.loginForm.sn = res.data.sn;
        }
      });
    },
  },
  computed: {
    phoneRight() {
      // 验证手机号码是否正确
      return /^[1][3,4,5,7,8][0-9]{9}$/.test(this.phone);
    },
  },
  created() {
    this.getCaptcha();
  },
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
  background: #fff;
}

.login-container .login-inner {
  padding-top: 60px;
  width: 80%;
  margin: 0 auto;
}

.login-container .login-inner .login-header .login-logo {
  font-size: 40px;
  font-weight: bold;
  color: #0960bd;
  text-align: center;
}

.login-container .login-inner .login-header .login-header-title {
  padding-top: 40px;
  padding-bottom: 10px;
  text-align: center;
}

.login-container .login-inner .login-header .login-header-title > a {
  color: #333;
  font-size: 14px;
  padding-bottom: 4px;
  text-decoration: none;
}

.login-container
  .login-inner
  .login-header
  .login-header-title
  > a:first-child {
  margin-right: 40px;
}

.login-container .login-inner .login-header .login-header-title > a.current {
  color: #0960bd;
  font-weight: 700;
  border-bottom: 2px solid #0960bd;
}
.login-content {
  width: 80%;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 50%;
  margin: auto;
}

.login-container .login-inner .login-content > form > div input {
  width: 100%;
  height: 100%;
  padding-left: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: 0;
  font: 400 14px Arial;
}

.login-container .login-inner .login-content > form > div input:focus {
  border: 1px solid #0960bd;
}

.login-container .login-inner .login-content > form > div .login-message {
  position: relative;
  margin-top: 16px;
  height: 48px;
  font-size: 14px;
  background: #fff;
}


  .get-verification {
  width: 100px;
  height: 26px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border: 0;
  color: #ccc;
  font-size: 14px;
  background: transparent;
}

.login-container
  .login-inner
  .login-content
  > form
  > div
  .login-message
  .get-verification.phone_right {
  color: #0960bd;
}

.login-container .login-inner .login-content > form > div .login-verification {
  position: relative;
  margin-top: 16px;
  height: 48px;
  font-size: 14px;
  background: #fff;
}

.login-container
  .login-inner
  .login-content
  > form
  > div
  .login-verification
  .switch-show {
  position: absolute;
  right: 10px;
  top: 12px;
}


.login-container
  .login-inner
  .login-content
  > form
  > div
  .login-verification
  .switch-show
  img.on {
  display: block;
}

.login-container .login-inner .login-content > form > div .login-hint {
  margin-top: 12px;
  color: #999;
  font-size: 12px;
  line-height: 20px;
}

.login-container .login-inner .login-content > form > div .login-hint > a {
  color: #0960bd;
}

.login-container .login-inner .login-content > form .login-submit {
  display: block;
  width: 100%;
  height: 42px;
  margin-top: 30px;
  border-radius: 4px;
  background: #0960bd;
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 42px;
  border: 0;
}

.login-container .login-inner .login-content .login-back {
  display: block;
  width: 100%;
  height: 42px;
  margin-top: 15px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #0960bd;
  color: #0960bd;
  text-align: center;
  font-size: 16px;
  line-height: 42px;
}
.tit {
  width: fit-content;
  margin: auto;
  color: #0656c5;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.rightcopy {
  width: 90%;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.divText {
  text-align: center;
  color: #b1b5ad;
  font-size: 12px;
  margin-bottom: 5px;
}
</style>
